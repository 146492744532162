import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Dashboard from "./Dashboard";
import CompanyDetails from "./CompanyDetails";
import EnterpriseDashboard from "./EnterpriseDashboard";
import CopyTransfer from "./CopyTransfer";
import Operations from "./Operations";

const MainPage = () => {
  const location = useLocation();

  const ROUTES = [
    { path: "/", component: Dashboard },
    { path: "/company", component: CompanyDetails },
    { path: "/enterprise-dashboard", component: EnterpriseDashboard },
    { path: "/copy-transfer", component: CopyTransfer },
    { path: "/operations", component: Operations },
  ];

  if (!ROUTES.map((route) => route.path).includes(location.pathname)) {
    window.location.href = "/";
  }

  return (
    <Routes>
      {ROUTES.map((route, key) => {
        return (
          <Route key={key} path={route.path} element={<route.component />} />
        );
      })}
    </Routes>
  );
};

export default MainPage;
