import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { fetchApiData } from "../Utils/ApiCalls";
import { ENTERPRISE_CONTRACTS } from "../urls";
import useFormStatus from "../Hooks/useFormStatus";
import usePagination from "../Hooks/usePagination";
import Pagination from "./Pagination";
import { Loader } from "./Loader";
import { Alert } from "./Alert";
import { BILLING_FREQUENCY } from "../Constants/Constants";
import { isMobileDevice } from "../Utils/HelperFunctions";

const EnterpriseDashboard = () => {
  const [enterpriseData, setEnterpriseData] = useState([]);
  const [count, setCount] = useState();
  const [searchTxt, setSearchTxt] = useState("");
  const [loading, setLoading] = useState(false);

  const { errorMessage, handleApiResult } = useFormStatus();
  const {
    page,
    setPage,
    totalPages,
    pageNumbers,
    recordsPerPage,
    setRecordsPerPage,
  } = usePagination({ count });

  const fetchCompanyData = useCallback(async () => {
    const params = {
      page: page,
      page_size: recordsPerPage,
    };
    if (searchTxt.length > 0) params.q = searchTxt;
    setLoading(true);
    const res = await fetchApiData({
      endpoint: ENTERPRISE_CONTRACTS,
      apiRequest: "GET",
      params: params,
    });
    handleApiResult(res);
    setLoading(false);
    setEnterpriseData([]);
    if (res?.data?.results) {
      setEnterpriseData(res?.data?.results);
      const count = res?.data?.count;
      setCount(count);
    }
  }, [handleApiResult, page, recordsPerPage, searchTxt]);

  useEffect(() => {
    fetchCompanyData();
  }, [fetchCompanyData]);

  return (
    <div className="px-0 pb-5 justify-center">
      <Alert errorMessage={errorMessage} />
      <h1 className="mt-5 mb-5 text-center text-3xl font-semibold ">
        Enterprise Contract Dashboard
      </h1>
      <div className="col-3 d-flex m-2 p-1">
        <input
          type="text"
          value={searchTxt}
          onChange={(e) => {
            setSearchTxt(e.target.value);
            setPage(1);
          }}
          className="form-control me-1 w-fit"
          placeholder="Search"
        />
        <button className="btn btn-success">Search</button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="overflow-x-scroll">
            <ComapnyTable enterpriseData={enterpriseData} />
          </div>
          <div className="m-4">
            <Pagination
              totalPages={totalPages}
              setPage={setPage}
              page={page}
              pageNumbers={pageNumbers}
              recordsPerPage={recordsPerPage}
              setRecordsPerPage={setRecordsPerPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EnterpriseDashboard;

const ComapnyTable = ({ enterpriseData }) => {
  const [tableData, setTableData] = useState([]);

  const mobileDevice = isMobileDevice();

  useEffect(() => {
    const newDataFormat = [];
    enterpriseData.forEach((data, idx) => {
      const newContracts = [];
      let isEnterpriseDataAdded = false;
      let rowSpanCompany = 0; // Calculates total invoices in all the contracts of the company

      const contracts = data?.enterprise_contract;
      contracts.forEach((contract) => {
        const newContractFormat = [];

        const isActive = contract?.is_active ? "True" : "False";
        const invoices = contract?.invoices;

        rowSpanCompany = rowSpanCompany + (invoices?.length || 1);

        const newContract = {
          contract_id: contract?.id,
          is_active: isActive,
          start_date: contract?.start_date,
          end_date: contract?.end_date,
          next_billing_date: contract?.next_billing_date,
          billing_frequency: BILLING_FREQUENCY[contract?.billing_frequency],
          count_of_invoice_raised: contract?.count_of_invoice_raised,
          aerial_usage_last_invoice: contract?.aerial_usage_last_invoice / 100,
          remeasurement_usage_last_invoice:
            contract?.remeasurement_usage_last_invoice / 100,
          bpt_usage_last_invoice: contract?.bpt_usage_last_invoice / 100,
          aerial_contract_amount: contract?.aerial_contract_amount / 100,
          per_acre_cost: contract?.per_acre_cost / 100,
          remeasurement_contract_amount:
            contract?.remeasurement_contract_amount / 100,
          remeasurement_per_acre_cost:
            contract?.remeasurement_per_acre_cost / 100,
          bpt_contract_amount: contract?.bpt_contract_amount / 100,
          workable_sheet_cost: contract?.workable_sheet_cost / 100,
          addendum_sheet_cost: contract?.addendum_sheet_cost / 100,
          manual_worksheet_cost: contract?.manual_worksheet_cost / 100,
          invoice_id: invoices && invoices[0]?.id,
          invoice_created_at: invoices && invoices[0]?.created_at,
          invoice_url: invoices && invoices[0]?.invoice_url,
          no_of_invoices: invoices && invoices?.length,
        };

        if (!isEnterpriseDataAdded) {
          newContract.index = idx + 1;
          newContract.id = data?.id;
          newContract.name = data?.name;
          isEnterpriseDataAdded = true;
        }

        newContractFormat.push(newContract);

        invoices?.forEach((invoice, idx) => {
          if (idx !== 0) {
            newContractFormat.push({
              invoice_id: invoice?.id,
              invoice_url: invoice?.invoice_url,
              invoice_created_at: invoice?.created_at,
            });
          }
        });

        newContracts.push(newContractFormat);
      });

      if (newContracts.length) {
        newContracts.forEach((contract) => {
          if (contract.length) {
            contract[0].rowSpanCompany = rowSpanCompany; // Add row span for the company level in the first contract
            newDataFormat.push(...contract);
          }
        });
      }
    });
    setTableData(newDataFormat);
  }, [enterpriseData]);

  const colNames = [
    "#",
    "Company Name",
    "Company Id",
    "Enterprise Contract Id",
    "Is Contract Active",
    "Contract Start Date",
    "Contract End Date",
    "Next Billing Date",
    "Billing frequency",
    "Count of invoices raised",
    "Aerial Usage Last Invoice($)",
    "Remeasurement Usage Last Invoice($)",
    "BPT Usage Last invoice($)",
    "Aerial Contract Amount($)",
    "Per Acre Cost($)",
    "Remeasurement Contract Amount($)",
    "Remeasurement Per Acre Cost($)",
    "BPT Contract Amount($)",
    "Workable Sheet Cost($)",
    "Addendum Sheet Cost($)",
    "Manual Worksheet Cost($)",
    "Invoice Id",
    "Invoice Created At",
    "Invoice URL",
  ];

  const getClassName = (id) => {
    return (
      "border border-slate-300 bg-slate-200 p-3 " +
      (!mobileDevice &&
        (id === 0
          ? "sticky left-0 w-12"
          : id === 1
          ? "sticky left-14 w-52"
          : id === 22
          ? "sticky right-48 w-52"
          : id === 23
          ? "sticky right-0 w-52"
          : ""))
    );
  };
  return (
    <table className="table-auto w-max">
      <thead className="">
        <tr className="bg-slate-400">
          {colNames?.map((col, id) => {
            return <th className={getClassName(id)}>{col}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((data, id) => {
          const defaultClassName = "border border-slate-100 p-3 ";
          return (
            <tr key={id}>
              {data?.index !== undefined && (
                <td
                  className={
                    defaultClassName +
                    "p-4 bg-slate-200" +
                    (!mobileDevice && " sticky left-0")
                  }
                  rowSpan={data?.rowSpanCompany}
                >
                  {data?.index}
                </td>
              )}
              {data?.name !== undefined && (
                <td
                  className={
                    defaultClassName +
                    " bg-slate-100" +
                    (!mobileDevice && " sticky left-14 ")
                  }
                  rowSpan={data?.rowSpanCompany}
                >
                  {data?.name}
                </td>
              )}
              {data?.id !== undefined && (
                <td className={defaultClassName} rowSpan={data?.rowSpanCompany}>
                  {data?.id}
                </td>
              )}
              {data?.contract_id !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.contract_id}
                </td>
              )}
              {data?.is_active !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.is_active}
                </td>
              )}
              {data?.start_date !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.start_date?.substring(0, 10)}
                </td>
              )}
              {data?.end_date !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.end_date?.substring(0, 10)}
                </td>
              )}
              {data?.next_billing_date && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.next_billing_date?.substring(0, 10)}
                </td>
              )}
              {data?.billing_frequency !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.billing_frequency}
                </td>
              )}
              {data?.count_of_invoice_raised !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.count_of_invoice_raised}
                </td>
              )}
              {data?.aerial_usage_last_invoice !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.aerial_usage_last_invoice}
                </td>
              )}
              {data?.remeasurement_usage_last_invoice !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.remeasurement_usage_last_invoice}
                </td>
              )}
              {data?.bpt_usage_last_invoice !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.bpt_usage_last_invoice}
                </td>
              )}
              {data?.aerial_contract_amount !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.aerial_contract_amount}
                </td>
              )}
              {data?.per_acre_cost !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.per_acre_cost}
                </td>
              )}
              {data?.remeasurement_contract_amount !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.remeasurement_contract_amount}
                </td>
              )}
              {data?.remeasurement_per_acre_cost !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.remeasurement_per_acre_cost}
                </td>
              )}
              {data?.bpt_contract_amount !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.bpt_contract_amount}
                </td>
              )}
              {data?.workable_sheet_cost !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.workable_sheet_cost}
                </td>
              )}
              {data?.addendum_sheet_cost !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.addendum_sheet_cost}
                </td>
              )}
              {data?.manual_worksheet_cost !== undefined && (
                <td className={defaultClassName} rowSpan={data?.no_of_invoices}>
                  {data?.manual_worksheet_cost}
                </td>
              )}
              <td className={defaultClassName}>{data?.invoice_id || "N.A."}</td>
              <td
                className={
                  defaultClassName +
                  " bg-slate-100" +
                  (!mobileDevice && " sticky right-48")
                }
              >
                {data?.invoice_created_at?.substring(0, 10) || "N.A."}
              </td>
              <td
                className={
                  defaultClassName +
                  " bg-slate-100" +
                  (!mobileDevice && " sticky right-0")
                }
              >
                {data?.invoice_url ? (
                  <NavLink to={data?.invoice_url} className="text-primary">
                    Invoice Link
                  </NavLink>
                ) : (
                  "N.A."
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
