export const SIGNIN_ENDPOINT = "/api/dashboard/login/";
export const DASHBOARD_ENDPOINT = "/api/dashboard/register/";
export const SIGNOUT_ENDPOINT = "/api/dashboard/logout/";
export const ATTACH_PLAN_ENDPOINT = "/api/dashboard/attach_plan/";
export const FEATURE_REPORT_ENDPOINT = "/api/dashboard/feature_report/";
export const CONVERT_TO_ENTERPRISE = "/api/dashboard/convert_to_enterprise/";
export const INCREASE_FREE_TRIAL_ENDPOINT = "/api/dashboard/start_freetrial/";
export const FREE_PROPERTY_DETAILS_ENDPOINT = "/api/dashboard/free_property/";
export const COMPANY_DETAILS = "/api/dashboard/company_details/";
export const DOWNLOAD_COMPANY_DETAILS = "/api/dashboard/download_company_data/";
export const USAGE_SUMMARY_ENDPOINT = "/api/dashboard/usage_report/";
export const INVITE_USER_ENDPOINT = "/api/dashboard/add_user_to_company/";
export const DOWNLOAD_PRO_EXPIRED_USERS_LIST = "/api/dashboard/pro_expired/";
export const ENTERPRISE_CONTRACTS = "/api/dashboard/enterprise_billing/";
export const CREATE_CONTRACT = "/api/dashboard/enterprise_contract/";
export const DOWNLOAD_USAGE_SUMMARY_USL = "/api/companies/%s/monthly_report/";
export const DOWNLOAD_USAGE_SUMMARY_GG_ENDPOINT =
  "/api/dashboard/ground_guys_report/";

// Copy and Transfer Requests
export const FMS_UPLOAD = "/api/admin/requests/fms_upload/";
export const TRANSFER_REQUEST = "/api%s/transfer_requests/";
export const BULK_DUPLICATE = "/api%s/bulk_duplicate/";
export const CHANGE_OWNER = "/api%s/change_owner/";

// Update Contract for Enterprise
export const GET_ENTERPRISE_DETAILS =
  "/api/dashboard/enterprise_info/?email=%s";
export const UPDATE_ENTERPRISE = "/api/dashboard/update_enterprise/";

//Create Feature Report
export const CREATE_FEATURE_ENDPOINT = "/api/dashboard/create_feature/";
export const FOLDER_EXPORT = "/api/dashboard/folder_export/";

//Portfolio
export const PORTFOLIO_ENDPOINT = "/api/portfolio/";
export const PORTFOLIO_UPDATE = "/api/portfolio/%s/";
export const PORTFOLIO_EXECUTE = "/api/portfolio/%s/execute/";
