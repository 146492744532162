import React, { useEffect, useState } from "react";
import { fetchApiData } from "../Utils/ApiCalls";
import {
  CONVERT_TO_ENTERPRISE,
  CREATE_CONTRACT,
  UPDATE_ENTERPRISE,
} from "../urls";

import SearchComponent from "./SearchComponent";
import useFormStatus from "../Hooks/useFormStatus";
import {
  BILLING_FREQUENCY,
  BILLING_FREQUENCY_ENUM,
  CHECHBOX_FIELDS,
  CHECHBOX_FIELDS_BEAM,
  CONTRACT_INFO_FIELDS,
  COUNTRY,
  COUNTRY_CODE,
  GROUP_INPUT_DATA_BEAM,
  INITIAL_FORM_DATA,
  FIELDS_TO_REMOVE_FOR_BEAM,
} from "../Constants/Constants";
import { GROUP_INPUT_DATA } from "../Constants/Constants";
import {
  isValidPostalCode,
  setContractInfoData,
  setInitialFormData,
} from "../Utils/HelperFunctions";

const EnterpriseConversionForm = ({
  updateEnterprise,
  enterpriseAttributes,
  billingDetails,
  enterpriseContract,
  platformAccess,
  email,
  companyId,
  isBeam = false,
}) => {
  const { successMessage, errorMessage, handleApiResult } = useFormStatus();

  const [contractInfo, setContractInfo] = useState({});

  const [formData, setFormData] = useState({});

  const [isManual, setIsManual] = useState(true);
  const [enterpriseCreated, setEnterpriseCreated] = useState(false);
  const [isInvalidZip, setIsInvalidZip] = useState(false);
  const [defaultReport, setDefaultReport] = useState("");
  const [inputValue, setInputValue] = useState([]);
  const [isEndDateDisable, setIsEndDateDisable] = useState(false);
  const [isContractEditDisable, setIsContractDisable] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const initializeFormData = () => {
      const initialData = setInitialFormData({
        email,
        enterpriseAttributes,
        platformAccess,
      });
      setDefaultReport(initialData?.default_report);
      setFormData(initialData);
      const selectedOptions = initialData?.reports_given?.map((report) => {
        const label = report?.name;
        const value = report?.id;
        return { label, value };
      });
      setSelectedOptions(selectedOptions);
      setInputValue(selectedOptions.map((option) => option?.value));
      if (updateEnterprise) {
        if (!enterpriseAttributes?.is_pilot) {
          // Company is non pilot and company has contract
          // User cannot edit contract details and platform end date
          if (enterpriseContract) {
            setIsEndDateDisable(true);
            setIsContractDisable(true);
            setIsManual(false);
          }
        }
      }
      // updateEnterprise && setIsManual(false);
    };
    const initialiseContractData = () => {
      const initData = setContractInfoData({
        enterpriseContract,
        billingDetails,
      });
      setContractInfo(initData);
    };
    initializeFormData();
    initialiseContractData();
  }, [
    email,
    enterpriseAttributes,
    platformAccess,
    billingDetails,
    enterpriseContract,
    updateEnterprise,
  ]);

  useEffect(() => {
    const createContract = async () => {
      const response = await fetchApiData({
        endpoint: CREATE_CONTRACT,
        data: contractInfo,
      });

      handleApiResult(response);

      setEnterpriseCreated(false);
      if (response.error === null) {
        setFormData(INITIAL_FORM_DATA);
        setContractInfo(CONTRACT_INFO_FIELDS);
        setIsManual(true);
      }
    };

    if (!updateEnterprise) {
      // Create contract only when automated billing selected
      if (
        enterpriseCreated &&
        !isManual &&
        !(!isManual && formData?.is_pilot)
      ) {
        createContract();
      }
    }
  }, [
    enterpriseCreated,
    isManual,
    contractInfo,
    handleApiResult,
    updateEnterprise,
    formData,
  ]);

  const handleSubmit = (e) => {
    if (updateEnterprise) {
      updateEnterpriseContract(e);
    } else {
      convertToEnterprise(e);
    }
  };

  const updateEnterpriseContract = async (e) => {
    e.preventDefault();
    const { start_date, end_date, ...ent_attributes } = formData;
    const {
      address,
      city,
      zip,
      country,
      addendum_sheet_cost,
      aerial_contract_amount,
      billing_email,
      billing_frequency,
      bpt_contract_amount,

      company_name,
      manual_per_acre_cost,
      manual_threshold_acres,
      manual_worksheet_cost,
      per_acre_cost,
      remeasurement_contract_amount,
      remeasurement_per_acre_cost,
      start_date: contractStartDate,
      workable_sheet_cost,
    } = contractInfo;
    const billing_details = {
      name: company_name,
      address,
      city,
      zip,
      country,
      email: billing_email.toLowerCase(),
    };
    const data = {
      company: companyId,
      enterprise_attributes: {
        ...ent_attributes,
        default_report: defaultReport,
        reports_given: inputValue,
      },
      platform_access: {
        start_date,
        end_date,
      },
    };
    if (!isManual && !formData?.is_pilot) {
      data.enterprise_contract = {
        addendum_sheet_cost: addendum_sheet_cost * 100,
        aerial_contract_amount: aerial_contract_amount * 100,
        billing_frequency,
        bpt_contract_amount: bpt_contract_amount * 100,
        manual_per_acre_cost: manual_per_acre_cost * 100,
        manual_threshold_acres,
        manual_worksheet_cost: manual_worksheet_cost * 100,
        per_acre_cost: per_acre_cost * 100,
        remeasurement_contract_amount: remeasurement_contract_amount * 100,
        remeasurement_per_acre_cost: remeasurement_per_acre_cost * 100,
        start_date: contractStartDate + "T00:00:00+05:30",
        workable_sheet_cost: workable_sheet_cost * 100,
      };
      data.billing_details = billing_details;
    }
    const res = await fetchApiData({
      endpoint: UPDATE_ENTERPRISE,
      data,
    });
    handleApiResult(res);
    if (res.error == null) {
      setFormData(INITIAL_FORM_DATA);
      setContractInfo(CONTRACT_INFO_FIELDS);
      setIsManual(true);
    }
  };

  const convertToEnterprise = async (e) => {
    e.preventDefault();

    if (
      !isValidPostalCode(contractInfo.zip, contractInfo.country) &&
      !isManual
    ) {
      window.scrollTo(0, 0);
      setIsInvalidZip(true);
      return;
    }

    //change the date format of end_date
    const date = new Date(formData.end_date);
    date.setHours(23, 59, 59, 999);
    formData.end_date = date;

    // adding valued to reports given and default report fields
    formData.default_report = defaultReport;
    formData.reports_given = inputValue;

    if (isBeam) {
      FIELDS_TO_REMOVE_FOR_BEAM.forEach((field) => {
        delete formData[field];
      });
    }
    formData.email = formData.email.toLowerCase();
    // Make the POST request to the API with the form data
    const response = await fetchApiData({
      endpoint: CONVERT_TO_ENTERPRISE,
      data: formData,
    });

    handleApiResult(response);
    window.scrollTo(0, 0);
    if (response.error === null) {
      // convert dollars to cents
      setContractInfo({
        ...contractInfo,
        aerial_contract_amount: contractInfo.aerial_contract_amount * 100,
        per_acre_cost: contractInfo.per_acre_cost * 100,
        remeasurement_contract_amount:
          contractInfo.remeasurement_contract_amount * 100,
        remeasurement_per_acre_cost:
          contractInfo.remeasurement_per_acre_cost * 100,
        bpt_contract_amount: contractInfo.bpt_contract_amount * 100,
        workable_sheet_cost: contractInfo.workable_sheet_cost * 100,
        addendum_sheet_cost: contractInfo.addendum_sheet_cost * 100,
        manual_worksheet_cost: contractInfo.manual_worksheet_cost * 100,
        manual_per_acre_cost: contractInfo.manual_per_acre_cost * 100,
      });
      setEnterpriseCreated(true);
    } else {
      setIsManual(true);
    }

    formData.default_report = [];
    formData.reports_given = "";
  };

  return (
    <>
      <EnterpiseConversion
        successMessage={successMessage}
        errorMessage={errorMessage}
        handleApiResult={handleApiResult}
        isManual={isManual}
        setIsManual={setIsManual}
        handleSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
        defaultReport={defaultReport}
        inputValue={inputValue}
        setDefaultReport={setDefaultReport}
        setInputValue={setInputValue}
        setContractInfo={setContractInfo}
        contractInfo={contractInfo}
        updateEnterprise={updateEnterprise}
        isEndDateDisable={isEndDateDisable}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        isPilot={enterpriseAttributes?.is_pilot}
        isBeam={isBeam}
      />
      {!isManual && !formData?.is_pilot && (
        <EnterpriseBillingInfo
          successMessage={successMessage}
          errorMessage={errorMessage}
          handleApiResult={handleApiResult}
          handleSubmit={handleSubmit}
          isInvalidZip={isInvalidZip}
          contractInfo={contractInfo}
          setContractInfo={setContractInfo}
          updateEnterprise={updateEnterprise}
          isContractEditDisable={isContractEditDisable}
        />
      )}
    </>
  );
};

const EnterpiseConversion = ({
  errorMessage,
  successMessage,
  isManual,
  setIsManual,
  handleSubmit,
  formData,
  setFormData,
  setDefaultReport,
  setInputValue,
  setContractInfo,
  contractInfo,
  updateEnterprise,
  isEndDateDisable,
  selectedOptions,
  setSelectedOptions,
  isPilot,
  defaultReport,
  isBeam,
}) => {
  const handleDataChange = (e, field) => {
    if (field === "is_pilot") {
      setIsManual(true);
    }
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleCheckboxChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.checked });
  };

  return (
    <div>
      {(errorMessage || successMessage) && (
        <div
          className={`alert ${errorMessage ? "alert-danger" : "alert-success"}`}
          role="alert"
        >
          {errorMessage || successMessage}
        </div>
      )}
      <form onSubmit={handleSubmit} className="border p-4 w-full">
        <div className="input-group mb-3 ">
          <input
            type="Email"
            value={formData?.email}
            onChange={(e) => {
              handleDataChange(e, "email");
              setContractInfo({ ...contractInfo, email: e.target.value });
            }}
            className="form-control "
            placeholder="Admin Email"
            aria-label="Email"
            required={!updateEnterprise}
          />
        </div>
        {!isBeam && (
          <SearchComponent
            setInputValue={setInputValue}
            setDefaultReport={setDefaultReport}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            defaultReport={defaultReport}
          />
        )}
        {(isBeam ? GROUP_INPUT_DATA_BEAM : GROUP_INPUT_DATA).map(
          ({ key, inputs, showLabel, className }) => (
            <div className={className} key={key}>
              {inputs.map(({ valueKey, type, label, ariaLabel, ...props }) => {
                const disabled = valueKey === "end_date" && isEndDateDisable;
                return (
                  <React.Fragment key={valueKey}>
                    {showLabel && (
                      <span className="input-group-text">{label}</span>
                    )}
                    <input
                      type={type}
                      value={formData[valueKey]}
                      onChange={(e) => handleDataChange(e, valueKey)}
                      className="form-control"
                      aria-label={ariaLabel}
                      disabled={disabled}
                      {...props}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          )
        )}

        {(isBeam ? CHECHBOX_FIELDS_BEAM : CHECHBOX_FIELDS).map((field) => (
          <div className="form-check form-check-inline" key={field}>
            <input
              className="form-check-input"
              type="checkbox"
              checked={formData[field]}
              onChange={(e) => handleCheckboxChange(e, field)}
              id={field}
              disabled={field === "is_pilot" && !isPilot && updateEnterprise}
            />
            <label className="form-check-label" htmlFor={field}>
              {field.replace(/_/g, " ").charAt(0).toUpperCase() +
                field.replace(/_/g, " ").slice(1)}
            </label>
          </div>
        ))}

        <div className="form-check form-check-inline border-y-2 w-full mt-4 flex">
          <label className="form-check-label my-4 ">Billing type:</label>
          <div onClick={() => setIsManual(true)} className="pointer mt-4 mx-2">
            <input type="radio" checked={isManual} className="mx-2" />
            <label>Manual Billing</label>
          </div>
          {!formData?.is_pilot && (
            <div
              onClick={() => {
                setIsManual(false);
              }}
              className="pointer mt-4"
            >
              <input type="radio" checked={!isManual} className="mx-2" />
              <label>Automated Billing</label>
            </div>
          )}
        </div>
        {(isManual || (!isManual && formData?.is_pilot)) && (
          <div className="text-center">
            <button
              type="submit"
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 mt-3 rounded"
            >
              {updateEnterprise ? "Update Contract" : "Convert to Enterprise"}
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

const EnterpriseBillingInfo = ({
  errorMessage,
  successMessage,
  handleSubmit,
  isInvalidZip,
  contractInfo,
  setContractInfo,
  updateEnterprise,
  isContractEditDisable,
}) => {
  const handleDataChange = (e, field) => {
    setContractInfo({ ...contractInfo, [field]: e });
  };

  const setDates = (date) => {
    const year = parseInt(date?.substring(0, 4)) + 1;
    const endDate = year + date?.substring(4);
    setContractInfo({ ...contractInfo, start_date: date, end_date: endDate });
  };

  return (
    <div className="border p-4 w-full">
      {(errorMessage || successMessage || isInvalidZip) && (
        <div
          className={`alert ${
            errorMessage || isInvalidZip ? "alert-danger" : "alert-success"
          }`}
          role="alert"
        >
          {errorMessage || successMessage || "Please enter valid zip code"}
        </div>
      )}

      <h1 className="text-2xl ">Enterprise Billing Info</h1>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="input-group mb-3">
          <p className="input-group-text w-1/2">Company Name</p>
          <input
            type="text w-2/3"
            value={contractInfo.company_name}
            onChange={(e) => handleDataChange(e.target.value, "company_name")}
            className="form-control "
            placeholder="Company Name"
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Company Billing Email</p>
          <input
            type="email"
            value={contractInfo.billing_email}
            onChange={(e) => handleDataChange(e.target.value, "billing_email")}
            className="form-control "
            placeholder="Email"
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Address</p>
          <input
            type="text"
            value={contractInfo.address}
            onChange={(e) => handleDataChange(e.target.value, "address")}
            className="form-control "
            placeholder="Address"
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">City</p>
          <input
            type="text"
            value={contractInfo.city}
            onChange={(e) => handleDataChange(e.target.value, "city")}
            className="form-control "
            placeholder="City"
            required
          />
        </div>

        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Zipcode</p>
          <input
            type="text"
            value={contractInfo.zip}
            onChange={(e) => handleDataChange(e.target.value, "zip")}
            className="form-control "
            placeholder="Zipcode"
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Country</p>
          <div className="dropdown w-1/2">
            <button
              className="btn border text-dark dropdown-toggle w-full"
              type="button"
              data-toggle="dropdown"
              data-bs-toggle="dropdown"
            >
              {COUNTRY[contractInfo.country]}
            </button>
            <div className="dropdown-menu w-full">
              <span
                className="dropdown-item "
                onClick={(e) => handleDataChange(COUNTRY_CODE.USA, "country")}
              >
                United Stated
              </span>
              <span
                className="dropdown-item"
                onClick={(e) =>
                  handleDataChange(COUNTRY_CODE.CANADA, "country")
                }
              >
                Canada
              </span>
              <span
                className="dropdown-item"
                onClick={(e) =>
                  handleDataChange(COUNTRY_CODE.AUSTRALIA, "country")
                }
              >
                Australia
              </span>
            </div>
          </div>
        </div>
        <h1 className="text-2xl mb-3 mt-5">Enterprise Contract Info</h1>

        <div className="input-group mb-3">
          <p className="input-group-text w-1/2">Contract Start Date</p>
          <input
            type="date"
            value={contractInfo.start_date}
            onChange={(e) => {
              setDates(e.target.value);
            }}
            className="form-control "
            placeholder=""
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Contract End Date</p>
          <input
            type="date"
            value={contractInfo.end_date}
            className="form-control"
            disabled
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Billing Frequency</p>
          <div className="dropdown w-1/2">
            <button
              className="btn border text-dark dropdown-toggle w-full"
              type="button"
              data-toggle="dropdown"
              data-bs-toggle="dropdown"
              disabled={isContractEditDisable}
            >
              {BILLING_FREQUENCY[contractInfo?.billing_frequency]}
            </button>
            <div className="dropdown-menu w-full">
              <span
                className="dropdown-item "
                onClick={(e) =>
                  handleDataChange(
                    BILLING_FREQUENCY_ENUM.QUATERLY,
                    "billing_frequency"
                  )
                }
              >
                {BILLING_FREQUENCY[BILLING_FREQUENCY_ENUM.QUATERLY]}
              </span>
              <span
                className="dropdown-item"
                onClick={(e) =>
                  handleDataChange(
                    BILLING_FREQUENCY_ENUM.MONTHLY,
                    "billing_frequency"
                  )
                }
              >
                {BILLING_FREQUENCY[BILLING_FREQUENCY_ENUM.MONTHLY]}
              </span>
              <span
                className="dropdown-item"
                onClick={(e) =>
                  handleDataChange(
                    BILLING_FREQUENCY_ENUM.HALF_YEARLY,
                    "billing_frequency"
                  )
                }
              >
                {BILLING_FREQUENCY[BILLING_FREQUENCY_ENUM.HALF_YEARLY]}
              </span>
              <span
                className="dropdown-item"
                onClick={(e) =>
                  handleDataChange(
                    BILLING_FREQUENCY_ENUM.ANNUAL,
                    "billing_frequency"
                  )
                }
              >
                {BILLING_FREQUENCY[BILLING_FREQUENCY_ENUM.ANNUAL]}
              </span>
            </div>
          </div>
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Aerial Contract Amount ($)</p>
          <input
            type="text"
            value={contractInfo.aerial_contract_amount}
            onChange={(e) =>
              handleDataChange(e.target.value, "aerial_contract_amount")
            }
            className="form-control "
            placeholder=""
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Aerial Per Acre Cost ($)
          </p>
          <input
            type="text"
            value={contractInfo.per_acre_cost}
            onChange={(e) => handleDataChange(e.target.value, "per_acre_cost")}
            className="form-control"
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Per Acre Imagery Cost for Manual Measurement, Beyond Threshold Acres
            ($):
          </p>
          <input
            type="text"
            value={contractInfo.manual_per_acre_cost}
            onChange={(e) =>
              handleDataChange(e.target.value, "manual_per_acre_cost")
            }
            className="form-control"
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Threshold Acres for Imagery cost in Manual Measurments:
          </p>
          <input
            type="text"
            defaultValue="20"
            value={contractInfo.manual_threshold_acres}
            onChange={(e) =>
              handleDataChange(e.target.value, "manual_threshold_acres")
            }
            className="form-control"
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Aerial Remeasurement Contract Amount ($)
          </p>
          <input
            type="text"
            value={contractInfo.remeasurement_contract_amount}
            onChange={(e) =>
              handleDataChange(e.target.value, "remeasurement_contract_amount")
            }
            className="form-control "
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Aerial Remeasurement Per Acre Cost ($)
          </p>
          <input
            type="text"
            value={contractInfo.remeasurement_per_acre_cost}
            onChange={(e) =>
              handleDataChange(e.target.value, "remeasurement_per_acre_cost")
            }
            className="form-control "
            disabled={isContractEditDisable}
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Contract Amount for BPT ($)
          </p>
          <input
            type="text"
            value={contractInfo.bpt_contract_amount}
            onChange={(e) =>
              handleDataChange(e.target.value, "bpt_contract_amount")
            }
            disabled={isContractEditDisable}
            className="form-control "
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Workable Sheet Cost BPT ($)
          </p>
          <input
            type="text"
            value={contractInfo.workable_sheet_cost}
            onChange={(e) =>
              handleDataChange(e.target.value, "workable_sheet_cost")
            }
            disabled={isContractEditDisable}
            className="form-control "
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2">Addendum Sheet Cost BPT ($)</p>
          <input
            type="text"
            value={contractInfo.addendum_sheet_cost}
            onChange={(e) =>
              handleDataChange(e.target.value, "addendum_sheet_cost")
            }
            disabled={isContractEditDisable}
            className="form-control "
            required
          />
        </div>
        <div className="input-group mb-3 ">
          <p className="input-group-text w-1/2 text-wrap">
            Workable Sheet in Manual Measurement for BPT ($)
          </p>
          <input
            type="text"
            value={contractInfo.manual_worksheet_cost}
            onChange={(e) =>
              handleDataChange(e.target.value, "manual_worksheet_cost")
            }
            disabled={isContractEditDisable}
            className="form-control "
            placeholder=""
            required
          />
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 mt-3 rounded"
          >
            {updateEnterprise ? "Update Contract" : "Convert to Enterprise"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EnterpriseConversionForm;
